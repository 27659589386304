
import React from 'react'
import Layout from './Layout'
import {css} from '@emotion/core'
import {Link} from 'gatsby'
import {animated,useSpring} from 'react-spring'

const Frau = ({daten,titel,utitel,data}) => {
   const props = useSpring({from:{opacity:0,paddingLeft:"-100px",paddingTop:'20px'}, to:{opacity:1,paddingLeft:"-100px",paddingTop:'0px'},config:{duration:400}})
  // const props = useSpring({from:{opacity:0,padding:'20px'}, to:{opacity:1,padding:'0px'},config:config.slow})
    console.log(daten)
    console.log(titel)
    console.log(utitel)
   return(
  <Layout site={data.site}>
       <div css={css`
        display:flex;
        flex-direction:column;
        padding:20px;
       `}>
          <div>
              <Link
              css={css`
                color:tomato;
               
              `}
              to="/weltenfrauen"> &rarr; Fotos</Link>
          </div>
          <animated.div style={props} >
         <h1 style={props}>{titel}</h1>
         <span style={{fontWeight:400,letterSpacing:'0.03rem'}}>{utitel}</span>
         </animated.div>
         {daten.map((item,index)=>{
             return(
                 <p key={index}>{item.s}</p>
             )
         })}
       </div>
       <div>
           <img
           css={css`
          
           width:100%; 
           
        `}
           src={data.file.childImageSharp.fluid.srcWebp} alt="Feretscha"/>
       </div>
       <div>
              <Link
              css={css`
                color:tomato;
              
              `}
              to="/weltenfrauen"> &rarr; Fotos</Link>
          </div>
      </Layout>
    )
  }

  export default Frau
